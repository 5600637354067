import React from "react";
import Navbar from "../components/Navbar";
export default function Home() {
  return (
    <div>
      <Navbar />
      <h4>Home page</h4>
      <p>are you okay</p>
    </div>
  );
}
