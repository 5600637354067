import {
  collection,
  addDoc,
  doc,
  deleteDoc,
  query,
  where,
  getDocs,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import db from "./firebase";

export const handleNew = async () => {
  const name = prompt("Enter your name");
  const age = prompt("Enter your age");
  const sex = prompt("Enter your gender");

  const collectionRef = collection(db, "users");
  const payload = { name, age, sex, timestamp: serverTimestamp() };

  const docRef = await addDoc(collectionRef, payload);
  console.log("The new ID is :" + docRef.id);
};
export const handleEdit = async (id) => {
  const name = prompt("Enter your name");
  const age = prompt("Enter your age");
  const sex = prompt("Enter your gender");

  /*  console.log(id); */
  const docRef = doc(db, "users", id);
  const payload = { name, age, sex, timestamp: serverTimestamp() };
  await updateDoc(docRef, payload);
};
export const handleDelete = async (id) => {
  const docRef = doc(db, "users", id);
  await deleteDoc(docRef);
};
export const handleQueryDelete = async () => {
  const userInputName = prompt("Enter your name");

  const collectionRef = collection(db, "users");
  const q = query(collectionRef, where("name", "==", userInputName));

  const snapshot = await getDocs(q);
  const results = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  /*   console.log(result); */
  results.forEach(async (result) => {
    const docRef = doc(db, "users", result.id);
    await deleteDoc(docRef);
  });
};
