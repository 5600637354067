import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDmik1QGHuiJUy2Q_lRjptCtehQcr2o9R8",
  authDomain: "samba-23bc2.firebaseapp.com",
  projectId: "samba-23bc2",
  storageBucket: "samba-23bc2.appspot.com",
  messagingSenderId: "403325415158",
  appId: "1:403325415158:web:52c29e09343051d74486d7",
};

const app = initializeApp(firebaseConfig);

export default getFirestore();
