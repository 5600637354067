import React from "react";
import Navbar from "../components/Navbar";
import "./contactus.css";
import eml_icon from "../images/message.png";
import phon_icon from "../images/phone.png";
import loc_icon from "../images/location.png";

export default function ContactUs() {
  return (
    <div className="contactus">
      <Navbar />
      <div className="contact-col-left">
        <h1>contact in touch</h1>
        <ul>
          <li>
            <img src={eml_icon} alt="" />
            <p>Email Address</p>
          </li>
          <li>
            <img src={phon_icon} alt="" />
            <p>Phone number</p>
          </li>
          <li>
            <img src={loc_icon} alt="" />
            <p>Location</p>
          </li>
        </ul>
        <p>
          This samba's offical website, if you in touch with us. We reply what
          you think and help you alot.
        </p>
      </div>
      <div className="contact-col-right">
        <form>
          <label>Enter your Name</label>
          <input type="text" placeholder="Enter your name" required />
          <label>Enter your Email</label>
          <input type="email" placeholder="Enter your Email" required />
          <label>Enter your Phone</label>
          <input type="tel" placeholder="Enter your phone number" required />
          <textarea
            rows="10"
            placeholder="write you message"
            required
          ></textarea>
          <button type="submit" required>
            send a message
          </button>
        </form>
      </div>
    </div>
  );
}
