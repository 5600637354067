import React from "react";

export default function Register() {
  return (
    <div>
      <h3>Register page</h3>
      <form>
        <label>Enter your name</label>
        <br />
        <input type="text" /> <br />
        <label>Your age</label>
        <br />
        <input type="text" /> <br />
        <label>Enter your Email</label>
        <br />
        <input type="email" />
      </form>
    </div>
  );
}
