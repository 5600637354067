import React from "react";
import Navbar from "../components/Navbar";
import img1 from "../images/thankyou.jpg";

export default function About() {
  return (
    <div>
      <Navbar />
      <h3>About page</h3>
      <ol>
        <li>hey mike</li>
        <li>endet neh</li>
        <li>aleh</li>
      </ol>
      <img src={img1} alt="thank you" />
      <h1>About page</h1>
      <p>about page tells </p>

      <h1>Contact page</h1>
      <p>Contact page used for </p>

      <h1>Service page</h1>
      <p>Service page is a part of the website</p>

      <h1>Registration page</h1>
      <p>Registration page is used</p>
    </div>
  );
}
