import {
  collection,
  onSnapshot,
  /*   addDoc, */
  query,
  orderBy,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import db from "../firebase";
import {
  handleNew,
  handleEdit,
  handleDelete,
  handleQueryDelete,
} from "../utility";

import React from "react";
import Navbar from "../components/Navbar";

export default function Services() {
  const [users, setusers] = useState([{ name: "Loadind...." }]);
  console.log(users);
  useEffect(() => {
    const collectionRef = collection(db, "users");
    const q = query(collectionRef, orderBy("timestamp", "desc"));
    const unsub = onSnapshot(q, (onSnapshot) =>
      setusers(onSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    );
    return unsub;
  }, []);
  return (
    <div>
      <Navbar />
      <h3>Services page</h3>
      <h1>hey are you okay</h1>
      <button className="btn1" onClick={handleNew}>
        {" "}
        New data to FB
      </button>
      <button className="btn2" onClick={handleQueryDelete}>
        {" "}
        Query Delete
      </button>
      <ul>
        {users.map((users) => (
          <li key={users.id}>
            <button className="button1" onClick={() => handleEdit(users.id)}>
              Edit
            </button>
            <button className="button2" onClick={() => handleDelete(users.id)}>
              Delete
            </button>

            {users.name}
          </li>
        ))}
      </ul>
      <h1>Service page</h1>
      <p>We deliver the following services:</p>
      <ol>
        <li>Developing website</li>
        <li>Developing Mobie App</li>
        <li>Power system Analysis</li>
        <li>Different Programming language tutorials</li>
        <li>Electrical installation</li>
        <li>AutoCad tutorials</li>
      </ol>
    </div>
  );
}
