import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./images/thankyou.jpg";
import "./index.css";
/* import { useCollectionData } from "react-firebase-hooks/firestore"; */
import Home from "./pages/Home";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import Register from "./pages/Register";
import Services from "./pages/Services";
/* import Navbar from "./components/Navbar";
import { onSnapshot } from "firebase/firestore"; */

export default function App() {
  /* const query = collection(db, "OSS");
  const [docs, loading, error] = useCollectionData(query); */

  return (
    <>
      <div>
        {/* <Navbar /> */}
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/Services" element={<Services />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}
